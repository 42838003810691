import axios from 'axios'

class AccountFactory {

  async get (data: any) {
    try {
      let url = '/acc/accounts'
      var response = await axios.get(url, {
        params: {
          keyword: data.keyword,
          offset: data.offset,
          limit: data.limit,
          order_by: data.order_by,
          order_at: data.order_at
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async search (keyword:string) {
    try {
      let url = '/acc/accounts/search'
      var params = { keyword: keyword }
      var response = await axios.get(url, { params: params })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getByParent (keyword:string) {
    try {
      let url = '/acc/accounts'
      var params = { keyword: keyword }
      var response = await axios.get(url, { params: params })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async create (data: any) {
    try {
      let url = '/acc/accounts'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async update (id: any, data: any) {
    try {
      let url = `/acc/accounts/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async exportAccount () {
    try {
      let url = '/acc/account/export'
      var response = await axios.get(url, {
          responseType: 'arraybuffer'
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Master_Akun.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importAccount (data: any) {
    try {
      let url = '/acc/account/import'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async delete (id: any) {
    try {
      let url = `acc/accounts/${id}`
      var response = await axios.delete(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async bulkDelete (ids: Array<number>) {
    try {
      let url = `acc/accounts/bulk_delete`
      var response = await axios.post(url, {ids: ids})
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }
}

export let accountFactory = new AccountFactory()
